const unit = (unit: string) => {
  return unit !== '-' ? unit : ''
}

const shipping_temp = (shipping_temp: string) => {
  return shipping_temp !== '-' ? shipping_temp : ''
}

const cas = (cas: string) => {
  return cas !== '-' ? cas : ''
}

const purity = (purity: string) => {
  return purity !== '-' ? purity : ''
}

const leadtime_converter = (leadtime: string, manufacturer: string) => {
  if (manufacturer === "Sigma" && leadtime === 'In Stock') return 'срок поставки - 2 месяца'
  if (manufacturer === "Promega" && leadtime === 'In Stock') return 'срок поставки - 2-3 месяца'
  if (manufacturer === "CST" && leadtime === 'Sufficient stock') return 'срок поставки - 1.5 месяца'
  if (['Abclonal', 'MedChemExpress'].includes(manufacturer) && leadtime === 'In Stock') return 'срок поставки - 1.5 месяца'
  if (!["Macklin", "Sigma", "Acmec", "Aladdin", "Abcam", "Synthware", "Thermofisher", "Promega"].includes(manufacturer)) return leadtime
  if (leadtime === 'In Stock') return 'срок поставки - 1.5 месяца'

  return ''
}

const quantity = (quantity: string) => {
  if (quantity.indexOf("x") >= 0) {
    quantity = (parseFloat(quantity.split("x")[0]) * parseFloat(quantity.split("x")[1])).toLocaleString()
  }

  return quantity !== '-' ? quantity : ''
}

const lead_time = (leadtime : string) => {
  return leadtime !== '-' ? leadtime : ''
}

const check_is_cold = (shipping_temp: string) => {
  try {
    if (parseFloat(shipping_temp) < 9) return 'ДА'
    if (shipping_temp.indexOf('-')>=0) {
      if (parseFloat(shipping_temp.split('-')[0]) < 9) return 'Да'
      return 'НЕТ'
    } else {
      return 'НЕТ'
    }
  } catch (e) {
    return 'НЕТ'
  }
}

const alwaysCopy = (text: string) => {
  if (window.isSecureContext && navigator.clipboard) {
    navigator.clipboard.writeText(text)
  } else {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
  }
}

export {
  cas,
  unit,
  purity,
  quantity,
  leadtime_converter,
  lead_time,
  check_is_cold,
  shipping_temp,
  alwaysCopy
}